import Cookie from './../services/cookie'
import axios from './../libs/axios'
import store from './../store'

export default {

    redirectIfAuthenticated(to, from, next){

        const token = Cookie.getToken()
        let n;

        if(token){
            n = ({ name: 'home'})
        }

        next(n)

    },


    async redirectIfNotAuthenticated(to, from, next){

        const token = Cookie.getToken()
        const confirmation_token = Cookie.getLoginUniqueToken()

        if(!token){
            next( { name: 'login'})
        }
        else{

            await axios.get('odontodocs/me').then(res => {

                if(confirmation_token !== res.data.data.confirmation_token){
                    next( { name: 'logout'})
                }


                if(!res.data.data.owner && to.meta.owner) {
                    next( { name: 'usuario-nao-habilitado'})
                }

                if(!store?.state?.user?.user?.id){
                    store.commit('user/STORE_USER', res.data.data)
                }

            }).catch(() => {
                Cookie.deleteToken()
                next( { name: 'login'})
            })
            next()
        }

    },



}



