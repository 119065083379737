import i18n from "@/libs/i18n";

export default {

    getMessage(error, message) {

        if (error) {
            const genericMessage = i18n.t('Ocorreu_um_problema_inesperado_Tente_novamente_mais_tarde_ou_entre_em_contato_com_o_suporte')

            if (error.response && error.response.status >= 500) {
                return {
                    hasMessage: true,
                    message: genericMessage,
                    messageArray: error.response?.data?.errors ?? [],
                    color: 'danger'
                }
            }

            if (error.response && error.response.data.error_code) {

                return {
                    hasMessage: true,
                    message: Exceptions[error.response.data.error_code] ?? genericMessage,
                    messageArray: error.response?.data?.errors ?? [],
                    color: 'danger'
                }
            }

            return {
                hasMessage: true,
                message: message ?? error.response?.data?.message ?? genericMessage,
                messageArray: error.response?.data?.errors ?? [],
                color: 'danger'
            }
        } else {
            return {
                hasMessage: true,
                message: message,
                messageArray: '',
                color: 'success'
            }
        }
    }

}
