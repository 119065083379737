import axios from 'axios'

export default {

  namespaced: true,

  state: {
    laboratorios: [],
    laboSelecionado: '',
    nomeLaboSelecionado: '',
    configuracaoLaboSelecionado: {}
  },

  mutations: {
    STORE_LABORATORIOS(state, result) {
      state.laboratorios = result
    },
    STORE_LABO_SELECIONADO(state, result) {
      state.laboSelecionado = result
    },
    STORE_NOME_LABO_SELECIONADO(state, result) {
      state.nomeLaboSelecionado = result
    },
    STORE_CONFIG_LABO_SELECIONADO(state, result) {
      state.configuracaoLaboSelecionado = result
    }
  },

  getters: {
    selecionado: state => {
      if(state.laboSelecionado) {
        return state.laboSelecionado
      }else if(state.laboratorios.length){
        return state.laboratorios[0].id
      }
    },
    nomeLaboSelecionado: state => {
      if(state.laboSelecionado) {
        return state.laboratorios.find( v => v.id === state.laboSelecionado).nome
      }else if(state.laboratorios.length){
        return state.laboratorios[0].nome
      }
    },
    getPais(state) {
      return state.configuracaoLaboSelecionado.pais;
    }
  },

  actions: {
    listaLaboratorios(context) {
      axios('odontodocs/laboratorios').then( (res) => {
        context.commit('STORE_LABORATORIOS', res.data)
        context.commit('STORE_LABO_SELECIONADO', res.data[0].id)
        context.commit('STORE_NOME_LABO_SELECIONADO', res.data[0].nome)
        context.commit('STORE_CONFIG_LABO_SELECIONADO',
            {
              odonto_etapas: res.data[0].odonto_etapas,
              odonto_financeiro: res.data[0].odonto_financeiro,
              pais: res.data[0].pais,
              idioma: res.data[0].idioma,
            }
        )
      })
    }
  }

}
